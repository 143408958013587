import { ExecutionStatus } from '@flow/flow-backend-types';
import { HISTORY_FILTER_DEFAULT_VALUE } from 'consts';
import { HistoryFilterGroup } from 'stores/flow';

export const FILTER_GROUPS: HistoryFilterGroup[] = [
  {
    type: 'statuses',
    filters: [
      { value: HISTORY_FILTER_DEFAULT_VALUE },
      { value: ExecutionStatus.done },
      { value: ExecutionStatus.cancelled },
      { value: 'pending' },
      { value: ExecutionStatus.expired },
    ],
  },
  {
    type: 'periods',
    filters: [
      { value: HISTORY_FILTER_DEFAULT_VALUE },
      { value: 'today' },
      { value: 'yesterday' },
      { value: 'thisWeek' },
      { value: 'last7Days' },
    ],
  },
  {
    type: 'executedBy',
    filters: [{ value: HISTORY_FILTER_DEFAULT_VALUE }, { value: 'me' }],
  },
];
